import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import i18n from "@/lang";

const routes = [
  {
    path: "/",
    name: "default",
    component: () => import("../views/index.vue"),
    meta: {
      title: i18n.t("meta.home.page_title"),
      metaTags: [
        {
          name: "title",
          content: i18n.t("meta.home.title")
        },
        {
          name: "description",
          content: i18n.t("meta.home.description")
        },
        {
          name: "keywords",
          content: i18n.t("meta.home.keywords")
        }
      ]
    }
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/index.vue"),
    meta: {
      title: i18n.t("meta.home.page_title"),
      metaTags: [
        {
          name: "title",
          content: i18n.t("meta.home.title")
        },
        {
          name: "description",
          content: i18n.t("meta.home.description")
        },
        {
          name: "keywords",
          content: i18n.t("meta.home.keywords")
        }
      ]
    }
  },
  {
    path: "/about-us",
    name: "aboutUs",
    component: () => import("../views/about-us"),
    meta: {
      title: i18n.t("meta.about_us.page_title"),
      metaTags: [
        {
          name: "title",
          content: i18n.t("meta.about_us.title")
        },
        {
          name: "description",
          content: i18n.t("meta.about_us.description")
        },
        {
          name: "keywords",
          content: i18n.t("meta.about_us.keywords")
        }
      ]
    }
  },
  {
    path: "/usage-policy",
    name: "policy",
    component: () => import("../views/usage-policy"),
    meta: {
      title: i18n.t("meta.about_us.page_title"),
      metaTags: [
        {
          name: "title",
          content: i18n.t("meta.about_us.title")
        },
        {
          name: "description",
          content: i18n.t("meta.about_us.description")
        },
        {
          name: "keywords",
          content: i18n.t("meta.about_us.keywords")
        }
      ]
    }
  },
  {
    path: "/terms",
    name: "policy",
    component: () => import("../views/terms"),
    meta: {
      title: i18n.t("meta.terms.page_title"),
      metaTags: [
        {
          name: "title",
          content: i18n.t("meta.about_us.title")
        },
        {
          name: "description",
          content: i18n.t("meta.about_us.description")
        },
        {
          name: "keywords",
          content: i18n.t("meta.about_us.keywords")
        }
      ]
    }
  },
  {
    path: "/404",
    name: "notFound",
    // redirect: { name: "404" },
    component: () => import("../views/404")
  },
  {
    path: "*",
    name: "404",
    redirect: "/"
    // component: () => import('../views/404')
  }
];

function generateMeta(to) {
  document.title = to.meta.title;
  to.meta.metaTags
    .map(tagObj => {
      const metaTag = document.createElement("meta");
      for (let key in tagObj) {
        metaTag.setAttribute(key, tagObj[key]);
      }
      metaTag.setAttribute("data-vue-router-controlled", "");
      return metaTag;
    })
    .forEach(tag => document.head.appendChild(tag));
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  generateMeta(to);

  window.fbq("track", "ViewContent", {
    content_name: to.path
  });

  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
  next();
});

export default router;
