import Vue from "vue";
import "./plugins/validations";
import "./constants/pageDirection";
import "./directives";
import { truncate } from "@/plugins/validations/filters";
import { swiper, swiperSlide } from "vue-awesome-swiper";
// import "vue-awesome-swiper/node_modules/swiper/dist/css/swiper.css";
import "swiper/css/swiper.css";
import Button from "@/components/Button";
import MainJumbotron from "@/components/MainJumbotron/index";

Vue.component("Button", Button);
Vue.component("swiper", swiper);
Vue.component("swiperSlide", swiperSlide);
Vue.component("main-jumbotron", MainJumbotron);
Vue.filter("truncate", truncate);
