import { IndexData } from "@/helpers/apiMethods";

const settings = {
  state: {
    settings: []
  },
  mutations: {
    SET_SETTINGS(state, payload) {
      state.settings = payload;
    }
  },
  actions: {
    setSettings: ({ commit }) => {
      IndexData({
        reqName: "settings?pagination=all"
      })
        .then(res => {
          const { data } = res.data;
          const convertedSettings = data.reduce((acc, currentSetting) => {
            acc[currentSetting.key] = currentSetting.value;
            return acc;
          }, {});

          commit("SET_SETTINGS", convertedSettings);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};

export default settings;
