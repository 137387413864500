const localization = {
  state: {
    locales: ["en", "ar"],
    locale: "en"
  },
  mutations: {
    SET_LANG(state, locale) {
      if (state.locales.includes(locale)) {
        state.locale = locale;
        window.location.reload(); // refresh the page
      }
    }
  }
  //   getters: {
  //     locale(state) {
  //       return state.locale;
  //     }
  //   }
};

export default localization;
