<template>
  <section class="main_jumbotron">
    <b-container>
      <b-row class="justify-content-center">
        <b-col cols="12" md="7" class="main_jumbotron__info">
          <article>
            <h1 class="main-header">{{ $t("head_intro") }}</h1>
            <p class="secondary-header white" v-html="welcome_message"></p>
          </article>
          <section class="main_jumbotron__actions">
            <b-row>
              <b-col sm="6" cols="12" class="d-flex">
                <a
                  class="color-white font-bold"
                  href="https://play.google.com/store/apps/details?id=com.agrivizeta.user"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h6>{{ $t("user_app") }}</h6>
                  <img width="140px" src="@/assets/imgs/google.png" />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.agrivizeta.expert"
                  class="mx-2 color-white font-bold"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h6>{{ $t("expert_app") }}</h6>
                  <img width="140px" src="@/assets/imgs/google.png" />
                </a>
              </b-col>
            </b-row>
          </section>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Jumbotron",
  data() {
    return {
      welcome_message: "",
      showMenu: false
    };
  },
  computed: {
    ...mapGetters(["GlobalSettings", "settings"])
  },
  watch: {
    GlobalSettings: {
      handler() {
        this.welcome_message = this.settings("welcome_message");
      },
      immediate: true
    }
  }
};
</script>
