import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./lang";
import ReadMore from "vue-read-more";
import VueContentPlaceholders from "vue-content-placeholders";
import Cookies from "js-cookie";
import VueLazyload from "vue-lazyload";
// import "./assets/style/scss/main.scss";
// import "./assets/fonts/gelano/stylesheet.css";
import "./assets/fonts/icons/css/fontello.css";
// Bootstrap
import "./bootstrap";
import { loadAnalyticsScript } from "./analytics";
import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
// import VueFacebookPixel from "vue-analytics-facebook-pixel";
import VueMeta from "vue-meta";
// Vue.use(VueFacebookPixel);
var VueScrollTo = require("vue-scrollto");

// You can also pass in the default options
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
});
Vue.use(ReadMore);
Vue.use(BootstrapVue);
Vue.use(VueContentPlaceholders);
Vue.use(VueMeta);
Vue.use(VueLazyload, {
  lazyComponent: true
});
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  created() {
    loadAnalyticsScript();
    // Vue.analytics.fbq.init("179734876613259", {
    //   em: "info@agrivezita.net"
    // });
    if (!Cookies.get("language")) {
      Cookies.set("language", "ar");
    }
    this.$store.dispatch("setSettings");
  },
  render: h => h(App)
}).$mount("#app");
